import React, { useState } from 'react'
import SEOTools from '../components/SEOTools'
import styled from 'styled-components'
import { Alert, Button, Card, Form } from 'react-bootstrap'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const IndexPage = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [error, setError] = useState('')

    const handleSubmit = async e => {
        e.preventDefault()

        setError('')

        try {
            const response = await fetch(`/.netlify/functions/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                }),
            })

            if (response.ok) {
                await response.json()
                window.location.href = '/home/welcome'
            } else {
                const responseBody = await response.json()
                setError(responseBody.msg)
            }
        } catch (error) {
            setError('Error occurred in the application. Please try again later or contact support.')
        }
    }

    return (
        <div className='bg-light'>
            <SEOTools title='Login' />

            <StyledLoginContainer className='d-flex align-items-center justify-content-center container'>
                <div>
                    {error && (
                        <Alert variant='danger' onClose={() => setError('')} dismissible>
                            {error}
                        </Alert>
                    )}
                    <Card className='mb-5'>
                        <Card.Body className='px-5 py-5'>
                            <Card.Title className='mb-4'>Login to support portal</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId='formUsername'>
                                    <Form.Label className='text-muted'>Username</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Enter username'
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId='formPassword' className='mb-4'>
                                    <Form.Label className='text-muted'>Password</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Password'
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <div className='text-right'>
                                    <Button variant='success' type='submit' className='px-5'>
                                        Login
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </StyledLoginContainer>
        </div>
    )
}

const StyledLoginContainer = styled.div`
    height: 100vh;

    form .form-control,
    form .btn {
        font-size: 1.2rem;
        letter-spacing: 0.8px;
    }
`

export default IndexPage
